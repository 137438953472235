import axios from './../../request/axios.js'
import api from './../../request/api.js'

const studentService = {
  getStudent: function (params) {
    return axios.post(api.management.student.getStudent, params)
  },
  delStudent: function (params) {
    return axios.post(api.management.student.delStudent, params)
  },
  addStudent: function (params) {
    return axios.post(api.management.student.addStudent, params)
  },
  editStudent: function (params) {
    return axios.post(api.management.student.editStudent, params)
  },
  getClassId: function (params) {
    return axios.post(api.management.student.getClassId, params)
  },
  // 搜索学生
  searchStudent: function (params) {
    return axios.post(api.management.student.searchStudent, params)
  }
}
export default studentService
