import axios from 'axios'
import { Message } from 'element-ui'
import DataStore from '@/globals/storage/index'
import cookies from 'js-cookie'

const axiosInstance = axios.create()
axiosInstance.defaults.timeout = 300*1000
axiosInstance.interceptors.request.use(
  config => {
    const newConfig = { ...config }
    const TOKEN = cookies.get('web_token')
    if (TOKEN) {
      newConfig.headers.Authorization = 'Bearer ' + TOKEN
    }
    return newConfig
  },
  error => Promise.reject(error)
)

const handleErrorRequest = error => {
  const { response } = error
  const status = response ? response.status : 408
  if (response) {
    const { data } = response
    const message = data.msg || '服务器发送错误，请稍后再试'
    if (status === -1) {
      Message.error('登录状态过期，请重新登录')
      DataStore.clear()
    } else if (status === 10) {
      Message.error(error.errMsg)
    } else if (status === 403) {
      Message.error('没有权限，联系管理员')
    } else {
      Message.error(message)
    }
  } else {
    Message.error('网络超时')
  }
}

const successRes = res => {
  if (res.data.code < 0 && res.data.errMsg === '您的账号被异地登录,请重新登录') {
    DataStore.clear()
    location.reload()
  }
  switch (true) {
    case res.data.code === 1:
      return res.data
    case res.data.code < 0:
      Message.error(res.data.errMsg || '服务器发送错误，请稍后再试')
      return Promise.reject(res.data)
    default:
      return res.data
  }
}

const errorRes = error => {
  handleErrorRequest(error)
  return Promise.reject(error)
}

axiosInstance.interceptors.response.use(successRes, errorRes)

export default {
  post (url = '', data = {}, config = {}) {
    data.loginKey = DataStore.get('loginKey')
    data.uid = DataStore.get('uid')
    return axiosInstance.post(url, data, config)
  },
  put (url = '', data = {}, config = {}) {
    const loginKey = DataStore.get('loginKey')
    const uid = DataStore.get('uid')
    data.loginKey = loginKey
    data.uid = uid
    return axiosInstance.put(url, data, config)
  },
  get (url = '', params = {}, config = {}) {
    const OPTIONS = Object.assign({ params }, config)
    return axiosInstance.get(url, OPTIONS)
  },
  delete (url = '', params = {}, config = {}) {
    const OPTIONS = Object.assign({ params }, config)
    return axiosInstance.delete(url, OPTIONS)
  }
}
