<!-- 管理系统首页 -->
<template>
  <div class="home-container">

  </div>
</template>
<script>
import storage from '@/globals/storage/index.js'
import { Loading } from 'element-ui'
export default {
  name: 'Home',
  data () {
    return {
      show: 0
    }
  },
  components: {
    // homeGovernment,
    // homeOrganization,
    // homeSchool,
    // homeGrade
  },
  created () {
    this.serType()
  },
  beforeDestroy () {
    this.loadingInstance().close()
  },
  mounted () {
    this.loadingInstance()
  },
  methods: {
    loadingInstance () {
      return Loading.service({
        lock: true,
        text: '请稍候，数据加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    serType () {
      const roleType = Number(storage.get('roleType'))
      switch (true) {
        case [6, 10].some(item => item === roleType):
          this.$router.push({ name: 'managementHomeSchool' })
          break
        case [7].some(item => item === roleType):
          this.$router.push({ name: 'managementHomeGrade' })
          break
        case [99].some(item => item === roleType):
          this.$router.push({ name: 'managementHomeGovernment' })
          break
        case [9].some(item => item === roleType):
          this.$router.push({ name: 'managementHomeOrganization' })
          break
        default:
          this.show = 0
      }
    }
  }
}
</script>
<style lang="less" scoped>
.home-container{
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-color: #021055;
}
</style>
