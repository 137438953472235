import axios from './../../request/axios.js'
import api from './../../request/api.js'

const analysisService = {
  getScreening: function (params) {
    return axios.post(api.analysis.getScreening, params)
  },
  normal: function (params) {
    return axios.post(api.analysis.normal, params)
  },
  degree: function (params) {
    return axios.post(api.analysis.degree, params)
  },
  data: function (params) {
    return axios.post(api.analysis.data, params)
  },
  curve: function (params) {
    return axios.post(api.analysis.curve, params)
  },
  wordReport: function (params) {
    return axios.get(api.analysis.wordReport, params,{ timeout: 300*1000 })
  }
}
export default analysisService
