import managementBasicLayout from '@/components/managementBasicLayout/BasicLayout.vue'
// import detectionBasicLayout from '@/components/detectionBasicLayout/BasicLayout.vue'
import managementRouter from './managementRouter'
// import detectionRouter from './detectionRouter'
const login = () => import(/* webpackChunkName: "login" */ '@/views/login.vue')
const ChangePassword = () => import(/* webpackChunkName: "login" */ '@/views/ChangePassword.vue')

export default [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      nav: {
        jurisdiction: [1, 6, 10, 7, 99, 9]
      }
    }
  },
  {
    path: '/change/password',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: {
      nav: {
        jurisdiction: [1, 6, 10, 7, 99, 9]
      }
    }
  },
  {
    path: '/managementBasicLayout',
    component: managementBasicLayout,
    redirect: { name: 'managementHome' },
    children: managementRouter
  }
  // {
  //   path: '/detectionBasicLayout',
  //   component: detectionBasicLayout,
  //   redirect: { name: 'AnalysisChart' },
  //   children: detectionRouter
  // }
]
