import axios from './../../request/axios.js'
import api from './../../request/api.js'

const teacherService = {
  getTeacherList: function (params) {
    return axios.post(api.management.teacher.getTeacherList, params)
  },
  deleteTeacher: function (params) {
    return axios.post(api.management.teacher.deleteTeacher, params)
  },
  addTeacher: function (params) {
    return axios.post(api.management.teacher.addTeacher, params)
  },
  editTeacher: function (params) {
    return axios.post(api.management.teacher.editTeacher, params)
  },
  getTeacherById: function (params) {
    return axios.post(api.management.teacher.getTeacherById, params)
  }
}
export default teacherService
