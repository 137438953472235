<template>
  <div class="BasicLayout-container">
    <el-container>
      <el-aside  :width="`${this.isCollapse ? '65px' : '240px'}`" ><Aside  :isCollapse='isCollapse' /></el-aside>
      <el-container>
        <el-header><Header :isCollapse.sync='isCollapse'/></el-header>
        <el-main><router-view/></el-main>
        <!-- <el-footer>Footer</el-footer> -->
      </el-container>
    </el-container>
  </div>
</template>
<script>
import Aside from './BasicLayoutSidebar.vue'
import Header from './BasicLayoutHeader.vue'
export default {
  data () {
    return {
      isCollapse: false
    }
  },
  components: {
    Aside,
    Header
  }
}
</script>
<style lang="less" scoped>
.BasicLayout-container{
  height: 100%;
  width: 100%;
  // background-color: #021055;
  color: #fff;
  padding: 0;
  display: flex;
}
.el-aside{
  // background-color: #041E59;
  overflow-y: auto;
  &::-webkit-scrollbar{
    display: none !important;
  }
  scrollbar-color: transparent transparent;
  scrollbar-track-color: transparent;
  -ms-scrollbar-track-color: transparent;
}
.el-container{
  max-height: 100%;
  min-height: 100%;
  overflow-y: auto;
}
.el-container{
  flex: 1;
}
.el-header{
  height: 80px !important;
  padding: 0;
  // background-color: #021055;
  box-shadow: 0px 0px 10px 0px rgba(4, 30, 89, 0.25);
}
.el-main{
  padding: 0;
  flex: 1;
}
</style>
