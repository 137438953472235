import axios from './../../request/axios.js'
import api from './../../request/api.js'

const teacherService = {
  // 新增消息
  addSchoolMessage: function (params) {
    return axios.post(api.management.messageCenter.addSchoolMessage, params)
  },
  // 搜索消息
  searchSchoolMessage: function (params) {
    return axios.post(api.management.messageCenter.searchSchoolMessage, params)
  },
  // 修改消息
  editSchoolMessage: function (params) {
    return axios.post(api.management.messageCenter.editSchoolMessage, params)
  }
}
export default teacherService
