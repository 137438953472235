import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import XLSX from 'xlsx'
import 'echarts-liquidfill'
import * as echarts from 'echarts'
import Pagination from '@/components/common/pagination'
import FormButtonGroup from '@/components/common/form-button-group'
import VueClipboard from 'vue-clipboard2'

import AES from './utils/aes.js';
Vue.prototype.$aes = AES;

import modal from './utils/modal.js'
Vue.prototype.$modal = modal
 
import directives from './utils/directives'
import elTableInfiniteScroll from 'el-table-infinite-scroll'

Vue.prototype.$echarts = echarts
Vue.use(directives)
Vue.use(elTableInfiniteScroll)
Vue.use(VueClipboard)
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(XLSX)
Vue.component('Pagination', Pagination)
Vue.component('FormButtonGroup', FormButtonGroup)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
